import DashboardAnalysis from "../../component/cards/dashboardanalysis";
import { Link } from "react-router-dom";
import _route from "../../constants/routes";
import './admin.css'
import OrderTableList from "../../component/order/grocerytransactionTable";
import phone from '../../assets/images/phone_in_talk.svg'
import wifi from '../../assets/images/wifi.svg'
import electricity from '../../assets/images/electricity.svg'
import tv from '../../assets/images/tv.svg'
import { usePushNotification } from '../../hooks/push-notification-hook';
import grocery from '../../assets/images/local_grocery_store.svg'
import Card from '../../component/landingpage/sectionone/card';
import useGetGroceriesAllOrder from '../../hooks/orders/usegetpaygroceriesorder';
import { useEffect, useState } from "react";
import Modal from '../../component/modal-md'

export default function Dashboard() {
	const { isSubscribed, error, requestPermission } = usePushNotification();
	const [viewCryptoModal, setViewCryptoModal] = useState(false)
	const {getGroceriesAllOrder, data: groceriesData, loading:groceriesLoading} = useGetGroceriesAllOrder()

	const requestUserPermission = ()=>{		
		!isSubscribed && requestPermission()
	}

	
	const handleClose = ()=>{
		setViewCryptoModal(false)
	}

	
	const handleGetTransaction = async ()=>{
		getGroceriesAllOrder()
	}


	useEffect(()=>{
		handleGetTransaction()
		requestUserPermission()
	}, [])


	return ( 
	<>
		<div className="nk-content ">
		<div className="container-fluid">
			<div className="nk-content-inner">
			<div className="nk-content-body">
				<div className="nk-block-head nk-block-head-sm mb-4 mt-4">
				<div className="nk-block-between">
					<div className="nk-block-head-content">
					<h4 className="page-title cus-page-title">Overview</h4>
					<div className="nk-block-des">
						<p>An overview of your account, Enjoy the experience.</p>
					</div>
					</div>
					{/* .nk-block-head-content */}
				</div>
				{/* .nk-block-between */}
				</div>
				{/* .nk-block-head */}
				<div className="nk-block">
				<div className="row  dashboard-card-analysis pb-5 g-gs">
					
					<DashboardAnalysis
						title="Total User Wallet Balance"
						value="0.00"
						wallet
						// accept=" 6,512,224.384"
						// decline='33,611,719'
					/>
				</div>
				{/* .row */}
				</div>
				{/* .nk-block */}
				<div className="nk-block">
				<div className="row g-gs">
					<div className={`col-md-12`}>
						<div style={{maxWidth: 'none'}} className='widget-container justify-content-start p-0'>
							<Card 
								icon={phone}
								text="Airtime"
								route={_route._airtime}
								dashboard
							/>
							<Card 
								icon={wifi}
								text="Data"
								route={_route._data}
								dashboard
							/>
							<Card 
								icon={electricity}
								text="Electricity"
								route={_route._electricity}
								dashboard
							/>
							<Card 
								icon={tv}
								text="Cable TV"
								route={_route._cable}
								dashboard
							/>
							{/* <Card 
								icon={betting}
								text="Betting"
								route={_route._betting}
								dashboard
							/> */}
							<Card 
								icon={grocery}
								text="Groceries"
								route={_route._groceries}
								dashboard
							/>
							
						</div>
					{/* .card */}
					</div>
					<div className={`col-md-12`}>
					<div className="card h-100">
						<div className="nk-ecwg nk-ecwg2">
						<div className="card-inner pe-0 ps-0">
							<div className="card-title-group mt-n1 mb-2 pe-3 pe-sm-4 ps-3 ps-sm-4">
							<div className="card-title">
								<h6 className="title card-title text-black">Orders</h6>
							</div>
							<Link to={_route._transaction} className="mb-0 text-paybond">View All </Link>
							</div>
							<OrderTableList dashboard  data={groceriesData}loading={groceriesLoading}  />
						</div>
						{/* .card-inner */}
						</div>
						{/* .nk-ecwg */}
					</div>
					{/* .card */}
					</div>
					{/* .col */}
				</div>
				{/* .row */}
				</div>
				{/* .nk-block */}
			</div>
			</div>
		</div>
		</div>





	  {viewCryptoModal && (
		<Modal
		  handleClose={handleClose}
		  showModal={viewCryptoModal}
		>
		  
		  <div className="modal-body modal-body-lg text-center">
				<div className="nk-modal">
				  <em
					style={{
					  background:
						`url("${crypto?.logo?.url}")`,
					  backgroundPosition: "center",
					  backgroundSize: "cover",
					}}
					className="nk-modal-icon icon icon-circle icon-circle-xxl ni  bg-success"
				  />
				  <h4 className="nk-modal-title">{crypto.name}</h4>
				  <div className="nk-modal-text">
					<div className="caption-text">
					  Selling Rate :<strong>{crypto.sellRate} Naira to 1 USD</strong>
					  <br />
					  Buying Rate:
					  <strong>{crypto.sellRate} Naira to 1USD</strong>
					  <br />
					  Live Rate: {crypto.liveRate} USD to 1 Bitcoin
					</div>
					<div>
					  <small>Recieveing Information</small>
					  <p>{crypto?.recievingAddress?.wallet}</p>
					  <div
						style={{
						  background: `url("${crypto?.recievingAddress?.QRCode?.url}")`,
						  height: 100,
						  width: 100,
						  backgroundSize: "cover",
						  backgroundPosition: "center",
						  margin: "0 auto",
						}}
					  ></div>
					</div>
				  </div>
				  <div className="nk-modal-action">
					<a
					  href="javascript:void(0)"
					  className="btn btn-lg btn-mw btn-primary"
					  data-dismiss="modal"
					>
					  OK
					</a>
				  </div>
				</div>
			  </div>
			  <div className="modal-footer bg-lighter">
				<div className="text-center w-100">
				  {/* <p>
					Earn upto $25 for each friend your refer!{" "}
					<a href="javascript:void(0)">Invite friends</a>
				  </p> */}
				</div>
			  </div>
		</Modal>
	  )}


	</>

  );
}
