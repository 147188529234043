import { useEffect } from "react";
import _route from "../../constants/routes";
import "./admin.css";
import useGetAllNotification from "../../hooks/notification/usegetnotification";
import NotificationsList from "../../component/agent/notificationItem";

export default function Notification() {
	const {getAllNotification, data: bannerData, loading} = useGetAllNotification();

	const handleGetBanner = async () => {
		await getAllNotification("users");
	};


	useEffect(() => {
		handleGetBanner()
	}, []);


  return (
    <>

      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              
              {/* .nk-block-head */}
              <div className="nk-block">
    

				<NotificationsList notification={true} loading={loading} notifications={bannerData.filter(item => item?.for_who?.toLowerCase() === 'users')} />
               
              </div>
              {/* .nk-block */}
            </div>
          </div>
        </div>
      </div>

	  	  
	
    </>
  );
}
