import React, { useEffect, useRef, useState } from 'react'
import download from '../../assets/images/download.png'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import _route from '../../constants/routes';
import useGetWallet from '../../hooks/kyc/usegetwallet';
import Spinnar from '../../helpers/spinnar';
import Modal from "../../helpers/modal"
import { useLocation } from "react-router-dom";
import copy from '../../assets/images/copy.svg'

export default function DashboardAnalysis({value, title, accept, decline, wallet}) {
	const {getWallet, data, loading} = useGetWallet()
	const copyRef = useRef(null)
	  const location = useLocation();	
	const user = useSelector((state) => state.auth.user)
	const [showModal, setShowModal] = useState(false)
	const formatNumber = (num) => {
		return `N${new Intl.NumberFormat().format(parseFloat(num))}`;
	};

	useEffect(() => {
		if(user?.virtualAccount){
			getWallet()
		}
	}, [user])

	const handleCloseModal = () => {
		setShowModal(false)
	}

	
	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const activeTab = queryParams.get("account");
	
	
		if (user?.virtualAccount && activeTab?.toLowerCase() === "fundwallet") {
		  setShowModal(true)
		}
	  }, []);


	  const handleCopy = async (e) => {
			try {
					// Use the Clipboard API to write the text to the clipboard
					await navigator.clipboard.writeText(copyRef.current.innerText);
					window.NioApp.Toast('copied to clipboard.', 'success')
				} catch (error) {
					console.error("Failed to copy text: " + error);
					window.NioApp.Toast('Failed to copied to clipboard.', 'error')
			}
		}



  return (
    <>
      
      <div className="d-flex justify-content-center align-items-center">
        <div className="card wallet-card-bg box-shadow-none justify-content-center align-items-center bg-transparent card-full">
          <div className="card-inner">
            <div className="card-title-group justify-content-center align-start mb-0">
              <div className="card-title">
                <h6 className="subtitle text-white tb-ff">
                  {title}
                </h6>
              </div>
              {/* <div className="card-tools">
                <em
                  className="card-hint icon ni ni-help-fill"
                  data-toggle="tooltip"
                  data-placement="left"
                  title
                  data-original-title="Total Deposited"
                />
              </div> */}
            </div>
            <div className="card-amount justify-content-center" id="totalapprovedcrypto">
              
              <span className="amount text-white">{loading ? <Spinnar /> : formatNumber(data?.balance || 0)}</span>
            </div>
            <div className="invest-data mt-3">
              <div className="invest-data-amount g-2">
                {user?.virtualAccount  
				? <button  onClick={()=> setShowModal(true)} className="fund-wallet-btn">
                  Fund Wallet
                  <img src={download} alt='download-icon' />
                </button>
				: <Link to={_route._kyc} className="fund-wallet-btn">
				  Create Virtual Account
				  <img src={download} alt='download-icon' />
				</Link>
				}
                {decline && <div className="invest-data-history">
                  <div className="title">Total Declined</div>
                  <div className="amount" id="totaldeclinedcrypto">
                    {" "}
                    <span className="amount">₦ {decline}</span>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
        {/* .card */}
      </div>
		{showModal && <Modal
			handleClose={handleCloseModal}
			showModal={showModal}
			myStyle="modal-sm"
		>	
			<div style={{minHeight: 'auto'}} className="success-card">
				<div className='text-center'>
					<p className="payment-success-text text-paybond w-100 mb-0">Fund Account</p>
					<p className="setting-sub-title ">
						A 2% transaction fee will be charged on your deposit amount. 
					</p>
				</div>
				{/* <p className="text-center">{`Your airtime recharge of ${formatNumber(formData.amount)} to ${formData.customer} was successful `}</p> */}
				<div className='text-center'>
					<p className="setting-sub-title m-0">
						<span className='text-black'>Bank Name:</span>
					</p>
					<p className="setting-sub-title ">
						{user?.virtualAccount?.bankName}
					</p>
					<p className="setting-sub-title m-0">
						<span className='text-black'>Account Number:</span> 
					</p>
					<p className="setting-sub-title d-flex justify-content-center align-items-center">
						<span className='' ref={copyRef}>{user?.virtualAccount?.accountNumber}</span> <span className='d-flex justify-content-center align-items-center copy-btn '><img className='ms-1 ' src={copy} alt='copy-icon' onClick={() => handleCopy()} /></span>
					</p>
				</div>
			</div>
		</Modal>}
    </>
  );
}
