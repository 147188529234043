export const _signUpUser = process.env.REACT_APP_BASE_URL + "/auth/register"
export const _verifyUser = process.env.REACT_APP_BASE_URL + "/auth/verify-email"
export const _verifyOTP = process.env.REACT_APP_BASE_URL + "/auth/verify-otp"
export const _transactionPin = process.env.REACT_APP_BASE_URL + "/user/update/pin"
export const _updateUser = process.env.REACT_APP_BASE_URL + "/user/update"
export const _logInUser = process.env.REACT_APP_BASE_URL + "/auth/login"
export const _logOutUser = process.env.REACT_APP_BASE_URL + "/auth/logout"
export const _forgotPassword = process.env.REACT_APP_BASE_URL + "/auth/logout"
export const _billCategory = process.env.REACT_APP_BASE_URL + "/transaction/bills/category"
export const _billfromCategory = process.env.REACT_APP_BASE_URL + "/transaction/bills/biller"
export const _verifybill = process.env.REACT_APP_BASE_URL + "/transaction/bills/validate"
export const _paybill = process.env.REACT_APP_BASE_URL + "/transaction/bills/pay"
export const _createCard = process.env.REACT_APP_BASE_URL + "/transaction/card/create"
export const _billTransaction = process.env.REACT_APP_BASE_URL + "/transaction/bills/history"
export const _groceriesTransaction = process.env.REACT_APP_BASE_URL + "/order/get/all"
export const _getAllShopItems = process.env.REACT_APP_BASE_URL + "/groceries/get/all"
export const _addToCart = process.env.REACT_APP_BASE_URL + "/cart/add"
export const _createCheckOut = process.env.REACT_APP_BASE_URL + "/order/add"
export const _removeFromCart = process.env.REACT_APP_BASE_URL + "/cart/remove"
export const _dropFromCart = process.env.REACT_APP_BASE_URL + "/cart/drop"
export const _getCart = process.env.REACT_APP_BASE_URL + "/cart/get"
export const _getState = process.env.REACT_APP_BASE_URL + "/store/delivery-state/get"
export const _getFav = process.env.REACT_APP_BASE_URL + "/favourite/get/all"
export const _addToFavourite = process.env.REACT_APP_BASE_URL + "/favourite/add"
export const _removeFromFav = process.env.REACT_APP_BASE_URL + "/favourite/remove"
export const _createWallet = process.env.REACT_APP_BASE_URL + "/wallet/create"
export const _getWallet = process.env.REACT_APP_BASE_URL + "/wallet/balance"
export const _chargeWallet = process.env.REACT_APP_BASE_URL + "/wallet/charge"
export const _getAllNotification = process.env.REACT_APP_BASE_URL + "/notification/get/all"
export const _getAllSuperNotification = process.env.REACT_APP_BASE_URL + "/notification/get/all/nolimit"
export const _getSingleNotification = process.env.REACT_APP_BASE_URL + "/notification/get"
export const _getSingleSuperNotification = process.env.REACT_APP_BASE_URL + "/notification/get/nolimit"
export const _postNotification = process.env.REACT_APP_BASE_URL + "/notification/send"
export const _updateNotification = process.env.REACT_APP_BASE_URL + "/notification/seen"
export const _deleteNotification = process.env.REACT_APP_BASE_URL + "/notification/remove"
export const _updateAgent = process.env.REACT_APP_BASE_URL + "/user/update"
