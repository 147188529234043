import { useSelector } from 'react-redux'
import _route from "../../constants/routes";

export default function Profile() {
	const user = useSelector((state) => state.auth.user)
	// const {createWallet, data, loading} = useCreateWallet()




  return (
	<>
		<div className="tab-container col-sm-6 mx-auto">
			<h6 className="mt-4">Create Virtual Account</h6>
			<p className="setting-sub-title">
			What do you want to do today?
			</p>
			<div
			className="auth-form px-3 py-4 rounded-4"
			>
				<p className="setting-sub-title">
					Bank Name: {user?.virtualAccount?.bankName}
				</p>
				<p className="setting-sub-title">
					Account Number: {user?.virtualAccount?.accountNumber}
				</p>
			</div>
		</div>
	</>
  )
}
