import {useState} from 'react';
import Spinnar from "../../../helpers/spinnar";
import card from "../../../assets/images/card.png";
import wallet from "../../../assets/images/wallet.png";
import OtpInput from 'react-otp-input';
import ModalWithHeader from '../../modalwithtitle';
import { useSelector } from 'react-redux'

function PaymentButton({ loadingCard, loadingWallet, onWalletClick, showModal, setShowModal, pin, setPin, handleTransactionPinModalPayment }) {
	const user = useSelector((state) => state.auth.user)

	const handleSubmit = async (e)=>{			
		await onWalletClick(pin)
	}

	const handleClose = () => {
		setPin("")
		setShowModal(false);
	}
  return (
    <>
      <div className="form-group col-12 mb-1">
        <button type="submit" className="auth-btn btn btn-lg btn-primary btn-block bg-paybond" >
          {loadingCard ? <Spinnar /> : <>
            <img src={card} alt="card" className="me-2" />
            Pay With Card
          </>}
        </button>
      </div>
	{user?.virtualAccount &&
		<div className="form-group col-12">
			<button type="button" className="auth-btn btn btn-lg btn-primary btn-block bg-paybond" onClick={() => handleTransactionPinModalPayment()}>
			{loadingWallet ? <Spinnar /> : <>
				<img src={wallet} alt="card" className="me-2" />
				Pay With Wallet
			</>}
			</button>
		</div>
	}

{showModal && (
        <ModalWithHeader
          handleClose={handleClose}
          showModal={showModal}
		  header='Enter Transaction pin'
        >
			<div className="modal-body">
                      <form className="auth-form ">
						<div className='row gy-4 mb-4'>
							<div className="">
								<div className="form-group">
								<div className="otp-cont transaction-otp">
									<OtpInput
										value={pin}
										onChange={setPin}
										numInputs={4}
										separator={<span> </span>}
										renderInput={(inputProps, index) => 
											<input 
												key={index} {...inputProps} 												
												type="password"
												inputMode="numeric"
												onKeyPress={(e) => {
													// Allow only numeric keys
													if (!/[0-9]/.test(e.key)) {
													  e.preventDefault();
													}
												  }}
											/>
										}
										inputStyle={{
										width: '4rem',
										height: '4rem',
										margin: '0 0.5rem',
										fontSize: '2rem',
										borderRadius: 4,
										background: '#EDEDED',
										border: '1px solid #D9D9D9'
										}}
									/>
								</div>
								</div>
							</div>
						</div>


                      </form>

			</div>
			<div className="modal-footer">
				{/* <button type="button" className="btn btn-primary" data-dismiss="modal">Cancel</button>

				<button className="btn btn-danger">{loading ? <Spinnar /> : 'Delete'}
				</button> */}
				<div className="form-group col-sm-6">
					<button type='button' onClick={()=> handleSubmit()} className="auth-btn btn btn-lg btn-primary btn-block bg-paybond">
					{loadingWallet ? <Spinnar /> : 'Continue'}
					</button>
				</div>


			</div>
        </ModalWithHeader>
      )}
		

    </>
  );
}

export default PaymentButton;
