import myApi from "../common/interceptors/apiinterceptor";
import { _postNotification, _getAllNotification, _getSingleSuperNotification, _getAllSuperNotification, _getSingleNotification, _updateNotification, _deleteNotification } from "../../network/network"

const Adminservices = {
	getAllNotification: async (data, source) => {
	  return await myApi.get(_getAllNotification, {
		  params: {
			  for_who: data
			},
		  cancelToken: source.token,
	  }).then(async (res) => {
		return res;
	  });
  
	},
	getAllSupperNotification: async (data, source) => {
	  return await myApi.get(_getAllSuperNotification, {
		  params: {
			  for_who: data
			},
		  cancelToken: source.token,
	  }).then(async (res) => {
		return res;
	  });
  
	},
  PostNotification: async (data, source) => {
    return await myApi.post(_postNotification, data, {
	headers: {
		'Content-Type': 'multipart/form-data',
		},
      cancelToken: source.token,
    }).then(async (res) => {
      return res;
    });

  },
  getSingleNotification: async (id, data, source) => {
    return await myApi.get(`${_getSingleNotification}/${id}`, {
		params: {
			for_who: data
		  },
		cancelToken: source.token,
    }).then(async (res) => {
      return res;
    });

  },
  SupperSingleNotification: async (id, data, source) => {
    return await myApi.get(`${_getSingleSuperNotification}/${id}`, {
		params: {
			for_who: data
		  },
		cancelToken: source.token,
    }).then(async (res) => {
      return res;
    });

  },
  updateNotification: async (data, id, source) => {
    return await myApi.patch(`${_updateNotification}/${id}`, data, {
	headers: {
		'Content-Type': 'multipart/form-data',
		},
      cancelToken: source.token,
    }).then(async (res) => {
      return res;
    });
  },
  DeleteNotification: async (id, source) => {
    return await myApi.delete(`${_deleteNotification}/${id}`, {
      cancelToken: source.token,
    }).then(async (res) => {
      return res;
    });

  },

//   forgotPassword: async (email, source) => {
//     return await myApi.post(_forgotPassword, { username: email }, {
//       cancelToken: source.token
//     })

//   },

//   forgotPasswordOtp: async (email, otp, source) => {
//     return await myApi.post(_forgotPasswordOtp, { username: email, otp: otp }, {
//       cancelToken: source.token
//     })

//   },

//   setNewPassword: async (password, token, source) => {

//     return await myApi.post(_setnewpassword, { newpassword: password }, {
//       cancelToken: source.token
//     })

//   },


}

export default Adminservices