import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'
import TwoCharacterComponent from "../../helpers/twocharactercomponent";
import _route from "../../constants/routes";
import useLogOutUser from "../../hooks/auth/uselogout";
import Profile from "../../component/data/profile";
import envelop from "../../assets/images/envelope.svg"
import useGetAllNotification from "../../hooks/notification/usegetnotification";
import moment from "moment";
import { useEffect } from "react";


export default function _header() {
	const user = useSelector((state) => state.auth.user)
	const {getAllNotification, data: notificationData, notificationLoading} = useGetAllNotification();
	const {logOutUser, loading} = useLogOutUser()

	const handleMenu = ()=>{
	}

	const handleLogOut = async ()=>{
		await logOutUser()
	}

	

	const handleGetNotification = async () =>{
		await getAllNotification("users");
	}


	useEffect(()=> {
		handleGetNotification()
	}, [])
	return (
		
		<div className="nk-header nk-header-fixed is-light">
		<div className="container-fluid">
			<div className="nk-header-wrap my-1">
				<div className="nk-menu-trigger d-xl-none ms-n1">
					<a href="javascript:void(0)" onClick={() => handleMenu()} className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em className="icon ni ni-menu" /></a>
				</div>
				<div className="nk-header-brand d-xl-none">
					<Link to={_route._dashboard} className="logo-link">
						<img className="logo-dark logo-img" src="/assets/images/logo.svg" srcSet="/assets/images/logo.svg 2x" alt="logo-dark" />
					</Link>
				</div>
				<div className="nk-header-tools">
					<ul className="nk-quick-nav">
						
						<li className="dropdown notification-dropdown">
							<span className="dropdown-toggle nk-quick-nav-icon" data-bs-toggle="dropdown">
								<div className="icon-status icon-status-info"><em className="icon ni ni-bell" /></div>
								<div className="notification-count"><p className="notification-counter">{loading ? "0" : notificationData.filter(item => item.seen === false).length > 99 ? "99+"      : notificationData.filter(item => item.seen === false).length}</p></div>
							</span>
							<div className="dropdown-menu dropdown-menu-xl dropdown-menu-end">
								<div className="dropdown-head">
									<span className="sub-title nk-dropdown-title">Notifications</span>
								</div>
								<div className="dropdown-body">
									<div className="nk-notification">

										{
										notificationData.filter(item => item.seen === false).map((notification, index)=>{
											return (
												<Link to={`${_route._notification}/${notification._id}`} className="nk-notification-item dropdown-inner" key={index}>
													<div className="nk-notification-icon">
														<div className="svgicon-wrap little-wrap">
														<img style={{width: 20, height: 20}} className="svgicon" src={envelop} />
														</div>
														<div className={`unread-${notification.seen}`} />
													</div>
													<div className="nk-notification-content">
														<div className="nk-notification-text">{notification.title}</div>
														<div className="nk-notification-time">{notification.message.substring(0, 40)}...</div>
														<small> {moment(notification.createdAt).fromNow()}</small>
													</div>
												</Link>

												
											)
										})
										}
									</div>
								</div>
								<div className="dropdown-foot center">
									<Link to={_route._notification} className="text-paybond">View All</Link>
								</div>
							</div>
						</li>
						<li className="dropdown user-dropdown">
							<span className="dropdown-toggle me-n1" data-bs-toggle="dropdown">
								<div className="user-toggle">
									<div className="user-avatar sm">
										{/* {user.image ? <img src="/assets/images/avatar/avatar.png" alt='avatar' /> : <TwoCharacterComponent data={`${'Godwin'} ${"Robert"}`} />} */}
										{
											user?.avatar ?
											
											<img
											src={Profile[+user.avatar - 1]?.name || ''}
											alt="avatar"
											/>
											:									
											<span><TwoCharacterComponent data={user.fullname} /> </span>
										}
									</div>
									<div className="user-info d-none d-xl-block">
										<div className="user-name dropdown-indicator">{user.fullname}</div>
									</div>
								</div>
							</span>
							<div className="dropdown-menu dropdown-menu-md dropdown-menu-end">
								<div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
									<div className="user-card">
										<div className="user-avatar">
											<span><TwoCharacterComponent data={user.fullname} /></span>
										</div>
										<div className="user-info w-available me-5">
											<span className="lead-text">{user.fullname}</span>
											<span className="sub-text w-100 line-clamp-1">{user.username}</span>
										</div>
									</div>
								</div>
								<div className="dropdown-inner">
									<ul className="link-list">
										{/* <li><span ><em className="icon ni ni-user-alt" /><span>View Profile</span></span></li> */}
										<li className='pointer'><Link to={_route._setting}><em className="icon ni ni-setting-alt" /><span>Account Setting</span></Link></li>
										{/* <li><span ><em className="icon ni ni-activity-alt" /><span>Login Activity</span></span></li> */}
										{/* <li><span className="dark-switch" ><em className="icon ni ni-moon" /><span>Dark Mode</span></span></li> */}
									</ul>
								</div>
								<div className="dropdown-inner">
									<ul className="link-list">
										<li onClick={() => handleLogOut()}><a href="javascript:void(0)"><em className="icon ni ni-signout" /><span>Sign out</span></a></li>
									</ul>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>{/* .nk-header-wrap */}
		</div>{/* .container-fliud */}
	</div>
	);
}