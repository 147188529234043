import { useEffect, useState } from "react";
import DisplayWallet from "../../component/kyc/displaywallet";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import _route from "../../constants/routes";

export default function FundWallet() {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const user = useSelector((state) => state.auth.user)
  const navigate = useNavigate()


  const handleBackToSidebar = () => {
    setIsSidebarVisible(true);
  };



  
	// useEffect(() => {
	// 	if(!user?.virtualAccount){
	// 		navigate(_route._kyc)
	// 	}
	// }, [user])


  return (
    <>
      <div className="nk-content p-0">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className={`nk-chat`}>
              <div className={`nk-chat-body profile-shown show-content`}>
                <div className="nk-chat-panel">
                  <div className="back-button" onClick={handleBackToSidebar}>Back</div>
                  <div id="start-chat" className="w-100 py-3">
                      <DisplayWallet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
