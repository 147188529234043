import { useEffect, useState, useRef } from "react";
import AuthService from "../../services/user/auth";
import axios from "axios";
import _route from "../../constants/routes";

const useTransactionPin = () => {
    const [loading, setloading] = useState(false);
    const CancelToken = axios.CancelToken;
    const source = useRef();

   
    const transactionPin = async (data) => {
        if (source.current === undefined) {
            source.current = CancelToken.source();
          }
        try {
            setloading(true);
            const res = await AuthService.updateTransactionPin(data, source.current);
            if(!res) {
				window.NioApp.Toast('An error occured', "warning");
            }else{
                setloading(false);
                if(res.status === 200 || res.status === 201){
					console.log("res", res)
					window.NioApp.Toast(res.data.message, "success");
					return true
                }
            }
            
        } catch (error) {
            setloading(false);
            if (axios.isCancel(error)) {
            } else {
				const message = error?.response?.data?.message || error.message;
			
				window.NioApp.Toast(message, "warning");
            }
        }
       
    }
    
    useEffect(()=>{
        return () =>{ 
            if (source.current != null) source.current.cancel()
        }
    }, [])

    return {transactionPin, loading};
}
 
export default useTransactionPin;

