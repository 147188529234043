
import './landing.css'
import SectionOne from "../../component/landingpage/about/sectionone"
import SectionThree from '../../component/landingpage/about/sectionthree';
import SectionFour from '../../component/landingpage/home/sectionfive';
import SectionTwo from '../../component/landingpage/about/sectiontwo';


export default function About() {


	return ( 
	<>		
		<SectionOne />
		<SectionTwo />
		<SectionThree />
		<SectionFour />

	</>

  );
}
