import InputField from "../common/input"
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import useupDateUserPassword from "../../hooks/auth/useupdatepassword";
import Spinnar from "../../helpers/spinnar";
import passwordStrength from "../../pages/passwordstrengthvalidation";

export default function ChangePassword() {
	const [upper, setupper] = useState(false)
	const [lower, setlower] = useState(false)
	const [number, setnumber] = useState(false)
	const [character, setcharacter] = useState(false)
	const [minlength, setminlength] = useState(false)
	const {upDataUserPassword, loading} = useupDateUserPassword()
	const [formData, setFormData] = useState({
	  password: "",
	  confirm_password: "",
	  new_password: "",
	});

	
		
	const handlenameChange = (e) => {
		const { name, value } = e.target;
		setFormData((prv) => ({ ...prv, [name]: value }));
	};

	 

	  const handleSubmitForm = async(e)=>{
		e.preventDefault()
		if((lower && upper && minlength && number && character) === true){
			if(formData.new_password !== formData.confirm_password){
				window.NioApp.Toast("The passwords entered do not match.", "warning");
			}else{
				await upDataUserPassword(formData)
			}

		}else{
			window.NioApp.Toast("password is too weak", "warning");

		}
		
		
	  }

	  
	function checkPassword (e){
		passwordStrength.upper.test(e.target.value) ? setupper(true) : setupper(false)
		passwordStrength.lower.test(e.target.value) ? setlower(true) : setlower(false)
		passwordStrength.number.test(e.target.value) ? setnumber(true) : setnumber(false)
		passwordStrength.character.test(e.target.value) ? setcharacter(true) : setcharacter(false)
		passwordStrength.minlength.test(e.target.value) ? setminlength(true) : setminlength(false)
		setFormData(prv => ({...prv, new_password: e.target.value}))
	}

  return (
	<>
		<div id="security-setting" className="tab-container">
			<h6 className="mt-4">Change Password</h6>
			<p className="setting-sub-title">
			Set a unique password to protect your account.
			</p>
			<form
			onSubmit={handleSubmitForm}
			className="auth-form px-3 py-4 rounded-4"
			>
			{/* .form-group */}
			<div className="form-group">
				<InputField 
				label="Old Password"
				name="password"
				type="text"
				placeholder=""
				value={formData.password}
				change={handlenameChange}
				/>
			</div>
			<div className="form-group">
				<InputField 
				label="New Password"
				name="new_password"
				type="text"
				placeholder=""
				value={formData.new_password}
				change={checkPassword}
				
				/>
			</div>
			{/* .form-group */}
			<div className="form-group">
				<InputField 
				label="Confirm Password"
				name="confirm_password"
				type="tel"
				placeholder=""
				value={formData.confirm_password}
				change={handlenameChange}
				/>
			</div>
			<div className="mt-4 btn-container">
				<button className="delete-acct-btn w-100 bg-paybond text-white active-btn">
				{loading ? <Spinnar /> : "Save Changes"}
				</button>
			</div>
			</form>
		</div>
	</>
  )
}
