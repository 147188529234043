import { useState } from 'react'
import Spinnar from "../spinnar";

export default function EditCryptoForm({editCrypto, setEditCrypto, loading, handleUpdateCrypto}) {
	const [crypto, setCrypto] = useState({
		name: editCrypto ? editCrypto?.name : '',
		buyRate: editCrypto ? editCrypto?.buyRate : '',
		sellRate: editCrypto ? editCrypto?.sellRate : '',
		availability:{
			sell: editCrypto ? editCrypto?.availability?.sell : false,
			buy: editCrypto ? editCrypto?.availability?.buy : false
		},
		shortCode: editCrypto ? editCrypto?.shortCode : '',
		liveRate: editCrypto ? editCrypto?.liveRate : '',
		recievingAddress:{
			wallet: editCrypto ? editCrypto?.recievingAddress.wallet : '',		
		},
		QRCode: editCrypto ? editCrypto?.QRCode : '',
		logo: editCrypto ? editCrypto?.logo : '',


	})

	// Function to handle file change
	const handleFileChange = (e) => {
		const file = e.target.files[0]; // Get the selected file
		const reader = new FileReader();
	
		reader.onload = (event) => {
		// When file is read, update the state with the file contents
		setCrypto(prevState => ({
			...prevState,
			logo: file // Use event.target.result to get the file contents as a data URL
		}));
		const logoLabel = document.getElementById('logoLabel');
		if (logoLabel) {
			logoLabel.innerText = file.name;
		}
		};
	
		reader.readAsDataURL(file); // Read the file as a data URL
	};

	// Function to handle file change
	const handleQRCodeFileChange = (e) => {
		const file = e.target.files[0]; // Get the selected file
		const reader = new FileReader();
	
		reader.onload = (event) => {
		// When file is read, update the state with the file contents
		setCrypto(prevState => ({
			...prevState,
			QRCode: file // Use event.target.result to get the file contents as a data URL
		}));
		const qrCodeLabel = document.getElementById('qrCodeLabel');
		if (qrCodeLabel) {
			qrCodeLabel.innerText = file.name;
		}
		};
	
		reader.readAsDataURL(file); // Read the file as a data URL
	};

	const handleSubmit = (e) => {
	  e.preventDefault();
	  const formData = new FormData();
  
	  formData.append("name", crypto.name);
	  formData.append("buyRate", crypto.buyRate);
	  formData.append("sellRate", crypto.sellRate);
	  formData.append("availability[sell]", crypto.availability.sell);
	  formData.append("availability[buy]", crypto.availability.buy);
	  formData.append("shortCode", crypto.shortCode);
	  formData.append("logo", crypto.logo);
	  formData.append("recievingAddress[wallet]", crypto.recievingAddress.wallet);
	  crypto.QRCode && formData.append("QRCode", crypto.QRCode);
  
	  
	  handleUpdateCrypto(formData, editCrypto._id);
	};

  return (
	<>
	  
	  <div className="modal-body">
              <form
                className="form-validate is-alter"
				onSubmit={handleSubmit}
              >
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    Crypto Name
                  </label>
                  <div className="form-control-wrap">
                    <input
					  required
                      type="text"
					  value={crypto.name}
					  onChange={(e) => setCrypto(prv => ({...prv, name:e.target.value}))}
                      className="form-control"
                      id="full-name"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="email-address">
                    Buy Rate
                  </label>
                  <div className="form-control-wrap">
                    <input
					  required
                      type="number"
					  value={crypto.buyRate}
					  onChange={(e) => setCrypto(prv => ({...prv, buyRate:e.target.value}))}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="phone-no">
                    Sell Rate
                  </label>
                  <div className="form-control-wrap">
                    <input
					  required
                      type="number"
					  value={crypto.sellRate}
					  onChange={(e) => setCrypto(prv => ({...prv, sellRate:e.target.value}))}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">Availability</label>
                  <ul className="custom-control-group g-3 align-center">
                    <li>
                      <div className="custom-control custom-control-sm custom-checkbox">
                        <input
                          type="checkbox"
						  checked={crypto.availability.buy}
						  onChange={(e) => {setCrypto(prv => ({...prv, availability:{ ...prv.availability, buy:e.target.checked}}))}}
                          className="custom-control-input"
                          id="comBuy"
                        />
						
                        <label
                          className="custom-control-label"
                          htmlFor="comBuy"
                        >
                          Buy
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-control custom-control-sm custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="comSell"
						  checked={crypto.availability.sell}
						  onChange={(e) => setCrypto(prv => ({...prv, availability:{ ...prv.availability, sell:e.target.checked}}))}
                          name="sell"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="comSell"
                        >
                          Sell
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="form-group">				  
                  <label className="form-label" >
					Short Code
                  </label>
                  <div className="form-control-wrap">
                    <input
					  required
                      type="text"
					  value={crypto.shortCode}
					  onChange={(e) => setCrypto(prv => ({...prv, shortCode:e.target.value}))}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    Crypto Logo
                  </label>
                  <div className="form-control-wrap">
                    <div className="custom-">
                      <input
                        type="file"
                        name="logo"
						onChange={(e) => handleFileChange(e)}
                        // className="custom-file-input"
                        id="customLogoFile"
                      />
                      <label id='logoLabel' className="custom-file-label" htmlFor="customLogoFile">
                        {editCrypto?.logo?.filename ? editCrypto?.logo?.filename : 'Choose file'}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="phone-no">
                    Recieving Wallet Address
                  </label>
                  <div className="form-control-wrap">
                    <input
					  required
                      type="text"
                      name="walletaddress"
					  value={crypto.recievingAddress.wallet}
					  onChange={(e) => setCrypto(prv => ({...prv, recievingAddress:{ ...prv.recievingAddress, wallet:e.target.value}}))}
                      className="form-control"
                      id="phone-no"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    Recieving QR Code (optional)
                  </label>
                  <div className="form-control-wrap">
                    <div className="custom-file">
                      <input
                        type="file"
                        name="qrcode"
						onChange={(e) => handleQRCodeFileChange(e)}
                        className="custom-file-input"
                        id="customQrFiles"
                      />
                      <label
                        className="custom-file-label"
						id='qrCodeLabel'
                        htmlFor="customQrFiles"
                      >
					  {editCrypto?.recievingAddress?.QRCode?.filename ? editCrypto?.recievingAddress?.QRCode?.filename : 'Choose file'}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary tb-ff"
                  >
                     {loading ? <Spinnar /> : "Update Crypto"}
                  </button>
                </div>
              </form>
            </div>
	</>
  )
}
