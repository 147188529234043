import { _getWallet, _createWallet } from "../../network/network"
import myApi from "../common/interceptors/apiinterceptor";

const Airtimeservices = {
	getWallet: async (id, source) => {	  		
		return await myApi.get(`${_getWallet}??user=${id}`, {
			cancelToken: source.token,
		  }).then(async (res) => {
			return res;
		  });
	},
	createWallet: async (data, source) => {	  		
		return await myApi.post(_createWallet, data, {
			cancelToken: source.token,
		  }).then(async (res) => {
			return res;
		  });
	}

}

export default Airtimeservices