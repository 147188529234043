import InputField from "../common/input"
import { useEffect, useState } from "react";
import useCreateWallet from "../../hooks/kyc/usecreatewallet";
import Spinnar from "../../helpers/spinnar";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import _route from "../../constants/routes";


export default function Profile() {
	const {createWallet, data, loading} = useCreateWallet()
	const user = useSelector((state) => state.auth.user)
	const navigate = useNavigate()
	const [formData, setFormData] = useState({
		firstname: "",
		lastname: "",
		narration: "",
		bvn: "",
	});

		
	const handlenameChange = (e) => {
		const { name, value } = e.target;
		setFormData((prv) => ({ ...prv, [name]: value }));
	};


	  const handleSubmitForm = async(e)=>{
		e.preventDefault()
		await createWallet(formData) && navigate(`${_route._dashboard}?account=fundwallet`)
	  }

		

  return (
	<>
		<div className="tab-container col-sm-6 mx-auto">
			<h6 className="mt-4">Create Virtual Account</h6>
			<p className="setting-sub-title">
			What do you want to do today?
			</p>
			<form
			onSubmit={handleSubmitForm}
			className="auth-form px-3 py-4 rounded-4"
			>
			{/* .form-group */}
			<div className="form-group">
				<InputField 
				label="First Name"
				name="firstname"
				type="text"
				placeholder="Paul"
				value={formData.firstname}
				change={handlenameChange}
				/>
			</div>
			<div className="form-group">
				<InputField 
				label="Last Nmae"
				name="lastname"
				type="text"
				placeholder="Okoro"
				value={formData.lastname}
				change={handlenameChange}
				/>
			</div>
			{/* .form-group */}
			<div className="form-group">
				<InputField 
				label="BVN"
				name="bvn"
				type="number"
				placeholder="23481000000"
				value={formData.bvn}
				change={handlenameChange}
				/>
			</div>
			<div className="form-group">
				<InputField 
				label="Narration"
				name="narration"
				type="text"
				placeholder="Note"
				value={formData.narration}
				change={handlenameChange}
				/>
			</div>
			<div className="mt-4 btn-container">
				<button className="delete-acct-btn w-100 bg-paybond text-white active-btn">
				{loading ? <Spinnar /> : "Create Wallet"}
				</button>
			</div>
			</form>
		</div>
	</>
  )
}
