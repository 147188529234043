import CryptoJS from 'crypto-js';

const HashPasswordKey = process.env.REACT_APP_BASE_HASH_KEY;

function interCeptTokenFromResponse(res) {
  if (res?.refreshToken || res?.token) {
    const { refreshToken, token } = res;
    // Encrypt tokens before storing robert 
	var encrptToken
	var encrptRefreshToken
	if(token){
    encrptToken = CryptoJS.AES.encrypt(JSON.stringify(token), HashPasswordKey).toString();
	}
	if(refreshToken){
    encrptRefreshToken = CryptoJS.AES.encrypt(JSON.stringify(refreshToken), HashPasswordKey).toString();
	}
    // Set tokens to local storage
	if(refreshToken){
    localStorage.setItem('refreshToken', JSON.stringify(encrptRefreshToken));
	}
	
	if(token){
    localStorage.setItem('authorization', JSON.stringify(encrptToken));
	}
  }
}

export default interCeptTokenFromResponse;
