// src/hooks/usePushNotification.js
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux'
import axios from "axios";
import { initializePushNotifications } from '../services/push-notification-setup';

export const usePushNotification = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const user = useSelector((state) => state.auth.user)
  const CancelToken = axios.CancelToken;
  const source = useRef();
  const [error, setError] = useState(null);

  useEffect(() => {
	if (source.current === undefined) {
		source.current = CancelToken.source();
	  }
    const setupPushNotifications = async () => {
      try {
        if (!('Notification' in window)) {
          throw new Error('This browser does not support notifications');
        }

        if (Notification.permission === 'granted') {
          const subscription = await initializePushNotifications(user._id, source.current.token);
          setIsSubscribed(!!subscription);
        }
      } catch (err) {
        setError(err.message);
        console.error('Error setting up push notifications:', err);
      }
    };

    setupPushNotifications();
  }, []);

  const requestPermission = async () => {
	if (source.current === undefined) {
		source.current = CancelToken.source();
	  }
    try {
      const subscription = await initializePushNotifications(user._id, source.current.token);
      setIsSubscribed(!!subscription);
      return true;
    } catch (err) {
      setError(err.message);
      console.error('Error requesting permission:', err);
      return false;
    }
  };

  return { isSubscribed, error, requestPermission };
};
