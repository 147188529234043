import { useState } from "react";
import Spinnar from "../../helpers/spinnar";
import OtpInput from 'react-otp-input';
import useUpdateTransactionPin from '../../hooks/auth/useupdatetransactionpin';

export default function ChangePin() {
	const [pin, setPin] = useState("");
	const [confirmPin, setConfirmPin] = useState("");
	const {transactionPin, loading} = useUpdateTransactionPin();

	const handleClose = ()=>{
		setPin("")
		setConfirmPin("")
	}
	const handleSubmit = async (e)=>{
		e.preventDefault()
		const transactionData = {
			pin: confirmPin,
			old_pin: pin
		}
		await transactionPin(transactionData) && handleClose()
	}
	


  return (
	<>
		<div id="pin-setting" className="tab-container">
			<h6 className="mt-4">Change Transaction Pin</h6>
			<p className="setting-sub-title">
			Do not disclose this transaction pin to anyone.
			</p>
			
			<form onSubmit={handleSubmit} className="auth-form ">
						<div className='row gy-4 mb-4'>
							<div className="">
								<div className="form-group">
								<label className="form-label auth-label" htmlFor="email">Old transaction PIN</label>
								<div className="otp-cont">
									<OtpInput
										value={pin}
										onChange={setPin}
										numInputs={4}
										separator={<span> </span>}
										renderInput={(inputProps, index) => 
											<input 
												key={index} {...inputProps} 												
												type="password"
												inputMode="numeric"
												onKeyPress={(e) => {
													// Allow only numeric keys
													if (!/[0-9]/.test(e.key)) {
													  e.preventDefault();
													}
												  }}
											/>
										}
										inputStyle={{
										width: '4rem',
										height: '4rem',
										margin: '0 0.5rem',
										fontSize: '2rem',
										borderRadius: 4,
										background: '#EDEDED',
										border: '1px solid #D9D9D9'
										}}
									/>
								</div>
								</div>
							</div>
						</div>
						<div className='row gy-4 mb-4'>
							<div className="">
								<div className="form-group">
								<label className="form-label auth-label" htmlFor="email">New transaction PIN</label>
								<div className="otp-cont">
									<OtpInput
										value={confirmPin}
										onChange={setConfirmPin}
										numInputs={4}
										separator={<span> </span>}
										renderInput={(inputProps, index) => 
											<input 
												key={index} {...inputProps} 												
												type="password"
												inputMode="numeric"
												onKeyPress={(e) => {
													// Allow only numeric keys
													if (!/[0-9]/.test(e.key)) {
													  e.preventDefault();
													}
												  }}
											/>
										}
										inputStyle={{
										width: '4rem',
										height: '4rem',
										margin: '0 0.5rem',
										fontSize: '2rem',
										borderRadius: 4,
										background: '#EDEDED',
										border: '1px solid #D9D9D9'
										}}
									/>
								</div>
								</div>
							</div>
						</div>



                        {/* .form-group */}
                        <div className="form-group col-sm-6">
                          <button className="auth-btn btn btn-lg btn-primary bg-paybond btn-block">
                            {loading ? <Spinnar /> : 'Continue'}
                          </button>
                        </div>
                      </form>
		</div>
	</>
  )
}
